import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import api from './api'

interface CreateMailingListSignupArgs {
  email: string
  birthDate: string
  pageUrl: string
  referrer: string
}

async function createMailingListSignup(args: CreateMailingListSignupArgs) {
  await api.post('/api/v6/mailing_list_signups', {
    mailing_list_signup: {
      email: args.email,
      birth_date: args.birthDate,
      page_url: args.pageUrl,
      referrer: args.referrer,
    },
  })
}

export function useCreateMailingListSignupMutation(
  options: UseMutationOptions<void, Error, CreateMailingListSignupArgs, unknown> = {}
) {
  return useMutation({
    ...options,
    mutationFn: (args: CreateMailingListSignupArgs) => {
      return createMailingListSignup(args)
    },
  })
}
