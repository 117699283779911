import { InputHTMLAttributes } from 'react'
import { UseFormRegister } from 'react-hook-form'

type EditableFormInputType = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  error?: string
  register: UseFormRegister<any>
}

export default function EditableFormInput({
  label = '',
  error = '',
  register,
  className = '',
  ...rest
}: EditableFormInputType) {
  const { name = '' } = rest

  return (
    <div className={`flex flex-col items-center ${className}`}>
      {!!label && (
        <label
          className="w-full pb-[0.625rem] font-super-new text-xl text-grey-text"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        {...rest}
        className="flex w-full rounded-[0.375rem] bg-white px-[1.125rem] py-[0.75rem] text-sm.3 font-semibold text-black outline-none"
        {...(register && register(rest.name || '', { required: rest.required }))}
      />
      <p className="mb-0 mt-1.7 min-h-[1.5rem] w-full text-[0.8125rem] text-orange">{error}</p>
    </div>
  )
}
