import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import Link from 'next/link'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { date as dateYup, object as objectYup, string as stringYup } from 'yup'
import { sleep } from '~/lib/utils/sleep'

import { PrimaryActionButton } from '~/components/elements/buttons/ActionButton'
import DatePickerInput from '~/components/elements/fields/DatePickerInput'
import EditableFormInput from '~/components/elements/fields/EditableFormInput'
import { useCreateMailingListSignupMutation } from '~/lib/api/useCreateMailingListSignupMutation'
import { useAppContext } from '~/lib/state_management/context/appContext'

type FooterEmailFormInputType = {
  email: string
  birthDate: string
}

// Set minBirthDate to 12 years ago
const dateLimit = new Date()
dateLimit.setFullYear(dateLimit.getFullYear() - 12)

const schema = objectYup({
  email: stringYup().required('Email is a required field').email('Invalid email'),
  birthDate: dateYup()
    .required()
    .min(dateLimit, "Please enter your child's birthdate or your due date")
    .typeError('Invalid birth date'),
}).required()

export default function FooterEmailForm() {
  const [submitted, setSubmitted] = useState(false)
  const { mutateAsync: createMailingListSignup } = useCreateMailingListSignupMutation()
  const appContext = useAppContext()

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<FooterEmailFormInputType>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      birthDate: '',
    },
  })

  const onSubmit: SubmitHandler<FooterEmailFormInputType> = async (data) => {
    const birthDate = dayjs(data.birthDate).format('YYYY-MM-DD')
    const stickyUtms = new URLSearchParams(appContext.stickyUtms).toString()
    const pageUrl = `${window.location.origin}${window.location.pathname}?${stickyUtms}`
    await createMailingListSignup({
      email: data.email,
      birthDate,
      pageUrl,
      referrer: document.referrer,
    })
    await sleep(1000) // sleep so that it feels like something is happening
    setSubmitted(true)
  }

  return (
    <div className="text-white">
      {submitted ? (
        <div className="mt-4 max-w-lg text-center text-white">
          <div className="text-4xl">👋</div>
          <div className="mt-4 text-3xl font-bold">Welcome to Tinyhood!</div>
          <div className="mt-4 px-8 text-base leading-6">
            We&apos;re so glad you&apos;re here with us! Check your email for your special offer.
          </div>
        </div>
      ) : (
        <>
          <div className="text-[1.2rem] font-bold leading-[1.8rem]">
            Get 15% off, advice from our experts, and secret deals from brands we love...
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-8 mt-4 flex flex-col lg:mb-0 lg:flex-row lg:gap-8">
                <EditableFormInput
                  className="min-w-16"
                  label="EMAIL"
                  name="email"
                  register={register}
                  error={errors?.email?.message || ''}
                />
                <DatePickerInput
                  name="birthDate"
                  label="Due Date or Child's Birthdate"
                  control={control}
                />
              </div>
              <div className={`${Object.keys(errors).length > 0 && 'mt-4'} max-w-[270px]`}>
                <PrimaryActionButton
                  type="submit"
                  disabled={!isDirty}
                  showProgressIndicator={isSubmitting}
                  title="Sign up"
                />
                <div className="mt-4 text-xs text-grey-text">
                  By signing up, you agree to Tinyhood&apos;s{' '}
                  <Link className="underline" href="/terms">
                    User Agreement
                  </Link>{' '}
                  and{' '}
                  <Link className="underline" href="/privacy">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  )
}
