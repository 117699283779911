import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import { Control, Controller } from 'react-hook-form'

type DatePickerInputProps = {
  name: string
  label: string
  control: Control<any, any>
  warning?: string | null
}

export default function DatePickerInput({ name, label, control, warning }: DatePickerInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          inputFormat="MM/DD/YY"
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div>
              <label className="w-full whitespace-nowrap font-super-new text-xl uppercase text-grey-text">
                {label}
              </label>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  flexDirection: 'flex-column',
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  className="mt-[0.3rem] flex w-full rounded-[0.375rem] bg-white px-[1.125rem] py-[0.75rem] text-sm.3 font-semibold text-black outline-none"
                />
                <div className="absolute mr-5 mt-1.5">{InputProps?.endAdornment}</div>
              </Box>
              {(error?.message || warning) && (
                <p className="mb-0 mt-1.7 min-h-[1.5rem] w-full text-[0.8125rem] text-orange">
                  {error?.message || warning}
                </p>
              )}
            </div>
          )}
        />
      )}
    />
  )
}
